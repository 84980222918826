import React from 'react';
import {BLOCKS, MARKS} from '@contentful/rich-text-types';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';

const Bold = ({children}) => <strong>{children}</strong>;
const Text = ({children}) => (
  <p style={{margin: '24px 0', lineHeight: 1.5}}>{children}</p>
);

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 style={{marginTop: 56}}>{children}</h3>
    ),
  },
};

export function renderRichContentfulText(json) {
  return documentToReactComponents(json, options);
}
